import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import EventsCmp from '../components/events';

const Events = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Eventos</title>
            <meta name="description" content="Entérate de los nuevos eventos que la Comisión de Energía realiza en toda la República Mexicana. ¡No te pierdas de ninguno!" />
        </Helmet>
        <main style={{ overflow: 'auto', height: '100vh' }}>
            <Header section="events" />
            <SubHeader title="Eventos" />
            <EventsCmp />
            <Footer />
        </main>
    </>
);

export default Events;
