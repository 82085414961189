import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import { Link } from '../common/Misc';
import styles from './styles';

const eventsQuery = graphql`
  query EventsQuery {
    content: allStrapiGrupoEventos(sort: { order: ASC, fields: name }) {
      edges {
        node {
            id
            name
            slug
            groupImage {
                localFile {
                    childImageSharp {
                        fluid {
                            srcSetWebp
                            srcSet
                            sizes
                            src
                        }
                    }
                }
            }
        }
      }
    }
  }
`;

const EventsCmp = () => {
    const { content } = useStaticQuery(eventsQuery);
    const { edges } = content;
    return (
        <section css={styles}>
            <div>
                {edges.map((edge) => (
                    <div
                        key={edge.node.id}
                        className="event-group"
                    >
                        <Link
                            to={`/eventos${edge.node.slug}`}
                            rel="nofollow noopener noreferrer"
                        >
                            {(() => {
                                if (edge.node.groupImage) {
                                    return (
                                        <img
                                            src={edge.node.groupImage.localFile.childImageSharp.fluid.src}
                                            alt={edge.node.name}
                                            className="event-image"
                                        />
                                    );
                                }
                                return <h4>{edge.node.name}</h4>;
                            })()}
                            <h6>Abrir</h6>
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default EventsCmp;
