import { css } from '@emotion/core';

export default css({
    width: '100%',
    padding: '65px 30px',
    boxSizing: 'border-box',
    background: 'rgb(249, 249, 249)',
    '& > div': {
        maxWidth: '1000px',
        margin: '0px auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridGap: '1em',
    },
    '& .event-group': {
        width: 300,
        height: 205,
        margin: 15,
        padding: 10,
        background: '#FFF',
        boxSizing: 'border-box',
        boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
        position: 'relative',
        justifySelf: 'center',
        '& > a': {
            transition: 'box-shadow 0.4s ease-in-out',
            textDecoration: 'none',
            color: '#1f300d',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '80%',
            '&:focus': {
                outline: 0
            }
        },
        '& .event-image': {
            width: '100%',
            maxWidth: 150,
        },
        '& h4': {
            width: '100%',
            fontSize: 21,
            textAlign: 'center',
            textTransform: 'uppercase',
            margin: '10px 0px 20px',
            lineHeight: '26px'
        },
        '& h6': {
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },
});
